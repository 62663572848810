import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '../helpers/fetchwrapper';

const name = 'charge';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

export const settingsActions = {...slice.actions, ...extraActions};
export const settingsReducer = slice.reducer;

function createInitialState() {
    return {
        priceOneKWh: null,
        error: null
    }
}

function createReducers() {
}

function createExtraActions() {
    const baseUrl = `api/settings`;

    return {
        getPriceOneKWh: getPriceOneKWh(),
        setDouble: setDouble()
    };

    function getPriceOneKWh() {
        return createAsyncThunk(
            `${name}/getPriceForOneKWh`,
            async () => await fetchWrapper.get(`${baseUrl}/get/PriceOneKWh`)
        );
    }

    function setDouble() {
        return createAsyncThunk(
            `${name}/setDouble`,
            async ({name, value}) => await fetchWrapper.post(`${baseUrl}/set/double`, {name, value})
        );
    }
}

function createExtraReducers() {
    return (builder) => {
        getPriceOneKWh();
        setDouble();

        function getPriceOneKWh() {
            let {pending, fulfilled, rejected} = extraActions.getPriceOneKWh;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state, action) => {
                    state.priceOneKWh = action.payload;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                });
        }
        function setDouble() {
            let {pending, fulfilled, rejected} = extraActions.setDouble;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                })
                .addCase(fulfilled, (state) => {
                    state.error = null;
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                });
        }
    };
}