import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDrag} from '@use-gesture/react'
import {a, useSpring, config} from '@react-spring/web'

import './styles/swipeModal.css';

export default SwipeModal;

function SwipeModal(props) {
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [{y}, api] = useSpring(() => ({y: height}))

    const open = useCallback(({canceled}) => {
        api.start({y: 0, immediate: false, config: canceled ? config.wobbly : config.stiff,});
    }, [api]);

    const close = (velocity = 0) => {
        api.start({y: height, immediate: false, config: {...config.stiff, velocity}})[0].then(() => {
            setTimeout(() => props.hide(), 200);
        });
    }
    const bind = useDrag(
        ({last, velocity: [, vy], direction: [, dy], offset: [, oy], cancel, canceled}) => {
            if (oy < -70) {
                cancel()
            }
            if (last) {
                if (oy > height * 0.5 || (vy > 0.5 && dy > 0)) {
                    close(vy)
                } else {
                    open({canceled})
                }
            } else {
                api.start({y: oy, immediate: true})
            }
        },
        {from: () => [0, y.get()], rubberband: true}
    )
    const modalStyle = {
        display: 'block', backgroundColor: 'rgba(0,0,0,0.4)', overflow: 'hidden'
    }
    useEffect(() => {
        if (contentRef.current) {
            setHeight(contentRef.current.clientHeight);
            open(false);
        }
    }, [contentRef, open, props])

    return (
        <>
            <div className='modal fade show' aria-hidden='true' style={modalStyle} onClick={() => close()}>
                <a.div className='modal-dialog modal-swipe'
                       style={{y, bottom: `calc(-${window.innerHeight}px + ${height}px)`}}
                       onClick={(e) => e.stopPropagation()} ref={contentRef}>
                    <a.div className='modal-content'>
                        <a.div className='modal-header' {...bind()} style={{touchAction: 'pan-y'}}>
                            <div className='modal-stick'></div>
                            <h5 className='modal-title'>{props.name}</h5>
                        </a.div>
                        <div className='modal-body'>
                            {props.body()}
                        </div>
                        <a.div className="modal-footer">
                            {
                                props.showBtn ?
                                    <button className='btn btn-success'
                                            onClick={() => {
                                                setHeight(0);
                                                setTimeout(() => props.success(), 500);
                                            }} disabled={props.blockBtn()}>{props.nameBtn}</button> :
                                    null
                            }
                        </a.div>
                    </a.div>
                </a.div>
            </div>
        </>);
}