import React from 'react';

import Widget from '../../shared/templates/Widget';
import ChargePointsTable from './ChargePointsTable';
import ModelEdit from './modals/ModalEdit';
import ModalInfo from './modals/ModalInfo';

import {fetchWrapper} from '../../../helpers/fetchwrapper';

export const Add = async () => {
    return ({
        title: 'Добавление станции',
        body:
            <>
                <div className="form-group">
                    <label htmlFor="name">Название</label>
                    <input type="text" className="form-control" id="name" placeholder="Введите название"/>
                </div>
                <div className="form-group">
                    <label htmlFor="name">Адрес</label>
                    <input type="text" className="form-control" id="address" placeholder="Введите адрес"/>
                </div>
            </>,
        saveName: 'Добавить',
        saveFunc: function (body) {
            let data = {
                name: body.find('#name').val(),
                address: body.find('#address').val(),
            };

            return fetchWrapper.post('api/chargepoints/add', data);
        }
    });
}

export const Edit = async (id) => {
    const $ = require('jquery');
    return ({
        title: 'Изменение станции',
        body: <ModelEdit id={id}/>,
        saveName: 'Сохранить',
        saveFunc: function (body) {
            let connectors = [];
            body.find('[name="id-connector"]').each((i, item) => {
                connectors[connectors.length] = {
                    id: $(item).val(),
                    name: $(item).parent().parent().find('[name="name-connector"]').val()
                }
            });
            let rates = [];
            body.find('[name="id-rate"]').each((i, item) => {
                rates[rates.length] = $(item).val();
            });
            let data = {
                id: body.find('#id').val(),
                name: body.find('#name').val(),
                address: body.find('#address').val(),
                connectorStatuses: connectors,
                rates: rates
            };

            return fetchWrapper.post('api/chargepoints/edit', data);
        }
    });
}

export const Info = async (id) => {
    return ({
        title: 'Просмотр станции',
        body: <ModalInfo id={id}/>,
    });
}

export default ChargePoints;

function ChargePoints(props) {

    return (
        <>
            <Widget>
                <h4>Зарядные станции</h4>
                <ChargePointsTable/>
            </Widget>
        </>
    );
}
