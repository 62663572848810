import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {IconContext} from 'react-icons';
import * as TbIcons from "react-icons/tb";

import './styles/menu.css';

export default Back;

function Back(props) {
    const path = useSelector((store) => store.back.path);

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <div className='menu'>
                        <Link to={path} className='back'><TbIcons.TbArrowLeft/></Link>
                </div>
            </IconContext.Provider>
        </>
    );
}
