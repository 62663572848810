import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {FaRegUserCircle} from "react-icons/fa";

import {authActions} from "../../store";
import {history} from "../../helpers/history";

import styles from './styles/profile.module.css'
import {fetchWrapper} from "@helpers/fetchwrapper";

function Profile() {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const [balance, setBalance] = useState(0);

    function logout() {
        return dispatch(authActions.logout())
            .unwrap()
            .then(() => {
                history.navigate('/login');
            });
    }

    useEffect(() => {
        fetchWrapper.get('api/users/balance').then((result) => {
            setBalance(result);
        })
    }, [])

    return (
        <div className='row text-center'>
            <div className={styles.profile}>
                <div className={styles.image}>
                    {<FaRegUserCircle/>}
                </div>
                <div className={styles.fio}>{user.lastName} {user.middleName} {user.firstName}</div>
                <div>
                    Лицевой счет:
                    <span className={styles.accountNumber} onClick={() => {
                        navigator.clipboard.writeText(user.id)
                    }}>{user.id}</span>
                </div>
                <div>
                    Баланс: {balance} тг
                </div>
                <div>
                    <Link to='/add-balance' className={`btn btn-outline-success mt-3 ${styles.btn}`}>Пополнить</Link>
                </div>
                <div>
                    <Link to='/contact-us' className={`btn btn-primary mt-3 ${styles.btn}`}>Связаться с нами</Link>
                </div>
                <div>
                    <Link to='/docs' className={`btn btn-secondary mt-3 ${styles.btn}`}>Документация</Link>
                </div>
                <div>
                    <button className={`btn btn-danger mt-3 ${styles.btn}`} onClick={logout}>Выход</button>
                </div>
            </div>
        </div>
    );
}

export default Profile;