import React, {useEffect} from 'react';
import Select from "react-select";
import * as TbIcons from "react-icons/tb";
import {fetchWrapper} from "../../../../helpers/fetchwrapper";

function ModalEdit({id}) {
    const [chosen, setChosen] = React.useState(null);
    const [chosenRate, setChosenRate] = React.useState(null);
    const [rates, setRates] = React.useState([]);

    const addRate = (id) => {
        let rate = rates.find(rate => rate.id === id);
        let newRates = [...chosen.chargeRates, rate];
        setChosen({
            ...chosen,
            chargeRates: newRates
        })
        setChosenRate(null);
    }

    const deleteRate = (id) => {
        setChosen({
            ...chosen,
            chargeRates: chosen.chargeRates.filter(rate => rate.id !== id)
        });
    }

    useEffect(() => {
        fetchWrapper.get(`api/chargepoints/${id}`).then(data => {
            setChosen(data);
        });
        fetchWrapper.get(`api/chargerates/all`).then(data => {
            setRates(data);
        })
    }, [id])

    return (
        <>
            {
                chosen != null ?
                    <>
                        <input type="hidden" id="id" value={id}/>
                        <div className="form-group">
                            <label htmlFor="name">Название</label>
                            <input type="text" className="form-control" id="name" placeholder="Введите название"
                                   defaultValue={chosen.name}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Адрес</label>
                            <input type="text" className="form-control" id="address" placeholder="Введите адрес"
                                   defaultValue={chosen.address}/>
                        </div>
                        <div className="mt-3 border border-dark p-2 rounded">
                            <h6>Коннекторы</h6>
                            {
                                chosen.connectorStatuses.map((item) => {
                                    return (
                                        <div key={item.id} className="row">
                                            <div className="form-group col-4">
                                                <label htmlFor="name">Номер</label>
                                                <input type="text" className="form-control" name="id-connector"
                                                       placeholder="Введите номер"
                                                       defaultValue={item.id} readOnly={true}/>
                                            </div>
                                            <div className="form-group col-8">
                                                <label htmlFor="name">Название</label>
                                                <input type="text" className="form-control" name="name-connector"
                                                       placeholder="Введите название"
                                                       defaultValue={item.name}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="mt-3 border border-dark p-2 rounded">
                            <h6>Тарифы</h6>
                            <div className="row align-items-center">
                                <div className='form-group col-10'>
                                    <label htmlFor="rates">Тарифы</label>
                                    <Select
                                        id='rates'
                                        options={
                                            rates.filter(rate =>
                                                chosen.chargeRates.filter(item => item.id === rate.id).length === 0).map(rate => {
                                                return ({
                                                    value: rate.id,
                                                    label: rate.name
                                                })
                                            })
                                        }
                                        isMulti={false}
                                        value={chosenRate}
                                        onChange={(e) => {
                                            setChosenRate(e)
                                        }}
                                        placeholder="Выберите тариф"
                                    />
                                </div>
                                <div className="form-group col-2 mt-4">
                                    <button onClick={() => {
                                        if (chosenRate) {
                                            addRate(chosenRate.value);
                                        }
                                    }} className="btn btn-sm btn-success" disabled={chosenRate === null}>
                                        <TbIcons.TbPlus/>
                                    </button>
                                </div>
                            </div>
                            <div>
                                {
                                    chosen.chargeRates.map((item) => {
                                        return (
                                            <div key={item.id} className="row align-items-center">
                                                <div className="form-group col-3">
                                                    <label htmlFor="id-rate">Номер</label>
                                                    <input type="text" className="form-control" name="id-rate"
                                                           placeholder="Введите номер"
                                                           defaultValue={item.id} readOnly={true}/>
                                                </div>
                                                <div className="form-group col-7">
                                                    <label htmlFor="name-rate">Название</label>
                                                    <input type="text" className="form-control" name="name-rate"
                                                           placeholder="Введите название"
                                                           defaultValue={item.name} readOnly={true}/>
                                                </div>
                                                <div className="form-group col-2 mt-4">
                                                    <button className="btn btn-sm btn-danger"
                                                            onClick={() => {
                                                                deleteRate(item.id);
                                                            }}>
                                                        <TbIcons.TbTrash/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
                    : null
            }
        </>
    );
};

export default ModalEdit;