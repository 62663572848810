import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PrivateComponent from './PrivateComponent';
import { history } from '../../helpers/history';

const SubMenu = ({ hide, item }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    const getShortPath = () => {
        let splited = history.location.pathname.split('/');
        if (splited.length > 2) {
            return `/${splited[1]}`
        }
        else {
            return history.location.pathname;
        }
    }

    getShortPath();

    return (
        <>
            <PrivateComponent>
                {
                    item.subNav ?
                        <div roles={item.roles}>
                            <div onClick={item.subNav && showSubnav}
                                className={getShortPath() === item.path ? item.cName + ' active' : item.cName}>
                                <div>
                                    {item.icon}
                                    <span>{item.title}</span>
                                    {item.subNav && subnav
                                        ? item.iconOpened
                                        : item.subNav
                                            ? item.iconClosed
                                            : null}
                                </div>
                            </div>
                            {subnav &&
                                item.subNav.map((item, index) => {
                                    return (
                                        <div key={index} onClick={hide}>
                                            <PrivateComponent>
                                                <div className={history.location.pathname === item.path ? item.cName + ' active' : item.cName}
                                                    roles={item.roles}>
                                                    <Link to={item.path} key={index}>
                                                        {item.icon}
                                                        <span>{item.title}</span>
                                                    </Link>
                                                </div>
                                            </PrivateComponent>
                                        </div>
                                    );
                                })}
                        </div> :
                        <div className={history.location.pathname === item.path ? item.cName + ' active' : item.cName}
                            roles={item.roles} onClick={hide}>
                            <Link to={item.path}>
                                {item.icon}
                                <span>{item.title}</span>
                            </Link>
                        </div>

                }
            </PrivateComponent>
        </>
    );
};

export default SubMenu;