import {Navigate, Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../../helpers/history';
import React from "react";
import {Role} from "../../helpers/role";

export default PrivateRoute;

function PrivateRoute(props) {
    const { user: authUser } = useSelector(x => x.auth);

    if (!authUser) {
        return <Navigate to='/login' state={{ from: history.location }} />
    }

    if(props.roles){
        if(Role.hasRole(props.roles, authUser.roles)){
            return <Outlet/>;
        }
        else{
            return <Navigate to='/' state={{ from: history.location }} />
        }
    }
    else{
        return <Outlet/>;
    }
}