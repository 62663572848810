import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '../helpers/fetchwrapper';

const name = 'charge';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

export const chargeActions = {...slice.actions, ...extraActions};
export const chargeReducer = slice.reducer;

function createInitialState() {
    return {
        step: null,
        points: null,
        point: null,
        connector: null,
        countKWh: 0,
        payment: {
            type: null,
            subType: null,
            info: null
        },
        paymentData: JSON.parse(localStorage.getItem('paymentData')),
        paymentResult: null,
        transactions: null,
        error: null
    }
}

function createReducers() {
    return {
        initCharging,
        cancel,
        setStep,
        setConnector,
        setCountKWh,
        setPayment
    };

    function initCharging(state) {
        state.step = 0;
        state.connector = null;
        state.countKWh = 0;
        state.payment = {
            type: null,
            subType: null,
            info: null
        };
        localStorage.removeItem('paymentData');
        state.paymentData = null;
        state.paymentResult = null;
        state.error = null;
    }

    function cancel(state) {
        state.step = null;
        state.connector = null;
        state.countKWh = 0;
        state.payment = {
            type: null,
            subType: null,
            info: null
        };
        localStorage.removeItem('paymentData');
        state.paymentData = null;
        state.paymentResult = null;
        state.error = null;
    }

    function setStep(state, action) {
        state.step = action.payload;
    }

    function setConnector(state, action) {
        state.connector = action.payload;
    }

    function setCountKWh(state, action) {
        state.countKWh = action.payload;
    }

    function setPayment(state, action) {
        state.payment = action.payload;
    }
}

function createExtraActions() {
    const baseUrl = `api/chargepoints`;
    const baseUrlOrders = `api/orders`;

    return {
        getOnlinePoints: getOnlinePoints(),
        getPoint: getPoint(),
        startTransaction: startTransaction(),
        stopTransaction: stopTransaction(),
        workingTransactions: workingTransactions(),
        checkStatusPayment: checkStatusPayment()
    };

    function getOnlinePoints() {
        return createAsyncThunk(
            `${name}/getOnlinePoints`,
            async () => await fetchWrapper.get(`${baseUrl}/online`)
        );
    }

    function getPoint() {
        return createAsyncThunk(
            `${name}/getPoint`,
            async ({id}, {getState}) => {
                let state = getState();
                if (id || state.charge.point) {
                    return await fetchWrapper.get(`${baseUrl}/${id ? id : state.charge.point ? state.charge.point.id : null}`)
                }
            }
        );
    }

    function startTransaction() {
        return createAsyncThunk(
            `${name}/startTransaction`,
            async (arg, {getState}) => {
                let state = getState();
                return await fetchWrapper.post(`${baseUrlOrders}/create`, {
                    id: state.charge.point.id,
                    connectorId: state.charge.connector,
                    countKWh: state.charge.countKWh,
                    paymentType: state.charge.payment
                })
            }
        );
    }

    function stopTransaction() {
        return createAsyncThunk(
            `${name}/remoteStop`,
            async (arg, {getState}) => {
                let state = getState();
                return await fetchWrapper.post(`${baseUrl}/remoteStop`, {
                    id: state.charge.point.id,
                    connectorId: state.charge.connector
                })
            }
        );
    }

    function workingTransactions() {
        return createAsyncThunk(
            `${name}/workingtransactions`,
            async () => {
                return await fetchWrapper.get(`${baseUrl}/workingtransactions`)
            }
        );
    }

    function checkStatusPayment() {
        return createAsyncThunk(
            `${name}/checkStatusPayment`,
            async (arg, {getState}) => {
                let state = getState();
                return await fetchWrapper.post(`${baseUrlOrders}/checkstatus`, {
                    id: state.charge.paymentData.id
                })
            }
        );
    }
}

function createExtraReducers() {
    return (builder) => {
        getOnlinePoints();
        getPoint();
        startTransition();
        stopTransition();
        workingTransactions();
        checkStatusPayment();

        function getOnlinePoints() {
            let {pending, fulfilled, rejected} = extraActions.getOnlinePoints;
            builder
            .addCase(pending, (state) => {
                state.error = null;
            })
            .addCase(fulfilled, (state, action) => {
                state.points = action.payload;
                if (state.point && !state.points.find(p => p.id === state.point.id)) {
                    if (state.step !== null) {
                        state.step = null;
                    }
                    state.point = null;
                }
            })
            .addCase(rejected, (state, action) => {
                state.error = action.error;
            });
        }

        function getPoint() {
            let {pending, fulfilled, rejected} = extraActions.getPoint;
            builder
            .addCase(pending, (state) => {
                state.error = null;
            })
            .addCase(fulfilled, (state, action) => {
                state.point = action.payload;
            })
            .addCase(rejected, (state, action) => {
                state.error = action.error;
            });
        }

        function startTransition() {
            let {pending, fulfilled, rejected} = extraActions.startTransaction;
            builder
            .addCase(pending, (state) => {
                state.error = null;
            })
            .addCase(fulfilled, (state, action) => {
                let paymentData = action.payload;
                localStorage.setItem('paymentData', JSON.stringify({
                    id: paymentData.id
                }));
                state.paymentData = paymentData;
            })
            .addCase(rejected, (state, action) => {
                state.step = 5;
            });
        }

        function stopTransition() {
            let {pending, fulfilled, rejected} = extraActions.stopTransaction;
            builder
            .addCase(pending, (state) => {
                state.error = null;
            })
            .addCase(fulfilled, (state, action) => {
                state.step = null;
            })
            .addCase(rejected, (state, action) => {
                state.error = action.error;
            });
        }

        function workingTransactions() {
            let {pending, fulfilled, rejected} = extraActions.workingTransactions;
            builder
            .addCase(pending, (state) => {
                state.error = null;
            })
            .addCase(fulfilled, (state, action) => {
                state.transactions = action.payload;
            })
            .addCase(rejected, (state, action) => {
                state.error = action.error;
            });
        }

        function checkStatusPayment() {
            let {pending, fulfilled, rejected} = extraActions.checkStatusPayment;
            builder
            .addCase(pending, (state) => {
                state.error = null;
            })
            .addCase(fulfilled, (state, action) => {
                state.paymentResult = action.payload;
                if (state.paymentResult > 0) {
                    state.step = 4;
                    localStorage.removeItem('paymentData');
                } else if (state.paymentResult < 0) {
                    state.step = 5;
                    localStorage.removeItem('paymentData');
                }
            })
            .addCase(rejected, (state, action) => {
                state.error = action.error;
            });
        }
    };
}