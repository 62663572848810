import React from 'react';

import Widget from '../../shared/templates/Widget';
import ChargeTagsTable from './ChargeTagsTable';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

export const Add = async () => {
    return ({
        title: 'Добавление карты',
        body:
            <>
                <div className="form-group">
                    <label htmlFor="number">Номер</label>
                    <input type="text" className="form-control" id="number" placeholder="Введите номер" />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Название</label>
                    <input type="text" className="form-control" id="name" placeholder="Введите название" />
                </div>
            </>,
        saveName: 'Добавить',
        saveFunc: function (body) {
            let data = {
                id: body.find('#number').val(),
                name: body.find('#name').val()
            };

            return fetchWrapper.post('api/chargetags/add', data);
        }
    });
}

export const Edit = async (id) => {
    let choosed = await fetchWrapper.get(`api/chargetags/${id}`);
    return ({
        title: 'Изменение карты',
        body:
            <>
                <input type="hidden" id="id" value={id} />
                <div className="form-group">
                    <label htmlFor="name">Название</label>
                    <input type="text" className="form-control" id="name" placeholder="Введите название" defaultValue={choosed.name} />
                </div>
            </>
        ,
        saveName: 'Сохранить',
        saveFunc: function (body) {
            let data = {
                id: body.find('#id').val(),
                name: body.find('#name').val(),
            };

            return fetchWrapper.post('api/chargetags/edit', data);
        }
    });
}

export default ChargeTags;

function ChargeTags(props) {

    return (
        <>
            <Widget>
                <h4>Карты</h4>
                <ChargeTagsTable />
            </Widget>
        </>
    );
}
