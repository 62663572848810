import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {BsFiletypeDocx} from "react-icons/bs";

import {backActions} from "../../store";

import Dogovor from "@content/docs/pdf/dogovor.pdf";
import Policy from "@content/docs/pdf/policy.pdf";
import Payment from "@content/docs/pdf/payment.pdf";

function Docs() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(backActions.needBack("/profile"));
        return (() => {
            dispatch(backActions.dispose());
        });
    }, [dispatch]);

    return (
        <div className='row text-center ps-2 pe-2'>
            <a href={Dogovor}
               className='d-flex justify-content-center align-items-center border-1 rounded mb-1 bg-dark-subtle'
               style={{height: 50, textDecoration: 'none', color: '#000'}}
               rel="noopener noreferrer" target="_blank">
                <div className='me-2'>
                    <BsFiletypeDocx size={30}/>
                </div>
                <div>
                    Договор-оферта
                </div>
            </a>
            <a href={Policy}
               className='d-flex justify-content-center align-items-center border-1 rounded mb-1 bg-dark-subtle'
               style={{height: 50, textDecoration: 'none', color: '#000'}}
               rel="noopener noreferrer" target="_blank">
                <div className='me-2'>
                    <BsFiletypeDocx size={30}/>
                </div>
                <div>
                    Политика конфиденциальности
                </div>
            </a>
            <a href={Payment}
               className='d-flex justify-content-center align-items-center border-1 rounded mb-1 bg-dark-subtle'
               style={{height: 50, textDecoration: 'none', color: '#000'}}
               rel="noopener noreferrer" target="_blank">
                <div className='me-2'>
                    <BsFiletypeDocx size={30}/>
                </div>
                <div>
                    Описание процедуры оплаты
                </div>
            </a>
        </div>
    );
}

export default Docs;