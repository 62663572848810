import {configureStore} from '@reduxjs/toolkit';

import {authReducer} from './auth.slice';
import {chargeReducer} from "./charge.slice";
import {backReducer} from "./back.slice";
import {settingsReducer} from "./settings.slice";

export * from './auth.slice';
export * from './charge.slice';
export * from './back.slice';
export * from './settings.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        charge: chargeReducer,
        back: backReducer,
        settings: settingsReducer
    },
});