import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {chargeActions} from "../../store";
import SwipeModal from "../shared/modals/SwipeModal";
import {bodies} from "../home/ModalBodies/SwipeModalBody";

import './styles/chargings.css'
import {RiBattery2ChargeLine} from "react-icons/ri";
import {BsLightningChargeFill} from "react-icons/bs";

function Chargings() {
    const dispatch = useDispatch();
    const transactions = useSelector((store) => store.charge.transactions);
    const step = useSelector((store) => store.charge.step);

    const refreshPoints = useCallback(() => {
        dispatch(chargeActions.workingTransactions());
    }, [dispatch]);

    useEffect(() => {
        refreshPoints();
        const timer = setInterval(() => {
            refreshPoints();
        }, 15000);
        return () => clearInterval(timer);
    }, [refreshPoints])

    function cancelCharging() {
        dispatch(chargeActions.cancel())
    }

    return (
        <div>
            {
                transactions ?
                    <div className="transactions-box">
                        {
                            transactions.map((item, index) => {
                                return (
                                    <div key={index} className="transaction-item" onClick={() => {
                                        let id = item.chargePointId;
                                        dispatch(chargeActions.getPoint({id}));
                                        dispatch(chargeActions.setConnector(item.connectorId));
                                        dispatch(chargeActions.setStep(4));
                                    }}>
                                        <div>
                                            <div className="transaction-point">Станция: {item.chargePointName}</div>
                                            <div className="transaction-connector">Коннектор: {item.connectorName}</div>
                                        </div>
                                        <div>
                                            <div className='transaction-percent'>
                                                {item.percent ? item.percent : 0}% {<RiBattery2ChargeLine/>}
                                            </div>
                                            <div className='transaction-kwh'>
                                                {item.kWh ? item.kWh : 0}KWh {<BsLightningChargeFill/>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> : null
            }
            {
                step === 4 ?
                    <SwipeModal hide={cancelCharging} body={bodies.waitForCharging}
                                name={"Идет зарядка"} success={() => {
                        dispatch(chargeActions.stopTransaction()).then(() => cancelCharging());
                    }} showBtn={true} blockBtn={() => false} nameBtn="Закончить"/> : null
            }
        </div>
    );
}

export default Chargings;