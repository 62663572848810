import React, {useCallback, useEffect, useMemo, useState} from "react";
import Select from "react-select";

import SwipeModal from "../shared/modals/SwipeModal";

import {BiSolidZap} from "react-icons/bi";

import CustomSwiper from "../shared/CustomSwiper";
import {bodies} from "./ModalBodies/SwipeModalBody";

import {useDispatch, useSelector} from "react-redux";
import {chargeActions, settingsActions} from "../../store";
import {history} from "../../helpers/history";

import 'swiper/css'
import 'swiper/css/bundle'
import './styles/home.css'

function Home() {
    const dispatch = useDispatch();
    const chargePoints = useSelector((store) => store.charge.points);
    const chargePoint = useSelector((store) => store.charge.point);
    const step = useSelector((store) => store.charge.step);
    const countKWh = useSelector((store) => store.charge.countKWh);
    const priceOneKWh = useSelector((store) => store.settings.priceOneKWh);
    const [swiperValue, setSwiperValue] = useState({id: 0, tag: null});
    const [checkPayment, setCheckPayment] = useState(0);
    const paymentResult = useSelector((store) => store.charge.paymentResult);

    const dataChooseConnector = useMemo(() => bodies.chooseConnector(chargePoint), [chargePoint]);
    const dataChoosePayment = useMemo(() => bodies.choosePayment(), []);

    const refreshPoints = useCallback(() => {
        dispatch(chargeActions.getOnlinePoints());
        dispatch(chargeActions.workingTransactions());
        dispatch(chargeActions.getPoint({id: null}));
    }, [dispatch]);

    const refreshPaymentState = useCallback(() => {
        dispatch(chargeActions.checkStatusPayment());
    }, [dispatch]);

    const getPriceSetting = useCallback(() => {
        dispatch(settingsActions.getPriceOneKWh());
    }, [dispatch])

    useEffect(() => {
        if (step === 3 && !checkPayment) {
            setCheckPayment(setInterval(() => {
                refreshPaymentState();
            }, 10000));
        } else if (checkPayment) {
            if (paymentResult && paymentResult !== 0) {
                clearInterval(checkPayment);
                setCheckPayment(null);
            }
        }

        return () => {
            if (checkPayment) {
                clearInterval(checkPayment);
                setCheckPayment(null);
            }
        };
    }, [step, checkPayment, paymentResult, refreshPaymentState])

    useEffect(() => {
        refreshPoints();
        const timer = setInterval(() => {
            refreshPoints();
        }, 15000);
        return () => clearInterval(timer);
    }, [refreshPoints])

    useEffect(() => {
        getPriceSetting();
    }, [getPriceSetting])

    const getSwiper = useCallback((data) => {
        return (
            <CustomSwiper
                data={data}
                object={setSwiperValue}
            />
        );
    }, [setSwiperValue])

    function getChargePoint(e) {
        let id = e.value;
        dispatch(chargeActions.getPoint({id}));
    }

    function handleSetCountKWh(e) {
        dispatch(chargeActions.setCountKWh(e.target.value));
    }

    const cancelCharging = useCallback(() => {
        dispatch(chargeActions.cancel())
    }, [dispatch]);

    const onSuccessChooseConnector = useCallback(() => {
        dispatch(chargeActions.setConnector(swiperValue.id));
        dispatch(chargeActions.setStep(1));
    }, [dispatch, swiperValue]);

    const onSuccessChooseCountKWh = useCallback(() => {
        dispatch(chargeActions.setCountKWh(countKWh));
        dispatch(chargeActions.setStep(2));
    }, [dispatch, countKWh]);

    const onSuccessChoosePayment = useCallback(() => {
        dispatch(chargeActions.setPayment({
            type: swiperValue.id,
        }))
        dispatch(chargeActions.setStep(3));
        dispatch(chargeActions.startTransaction());
    }, [dispatch, swiperValue]);

    const onSuccessWaitCharging = useCallback(() => {
        dispatch(chargeActions.stopTransaction()).then(() => cancelCharging());
    }, [dispatch, cancelCharging]);

    return (
        <div>
            <div>
                <div className='charging-box row'>
                    <div className='d-flex justify-content-center p-0'>
                        <Select
                            key={`select-point-${chargePoint ? chargePoint.id : 'none'}`}
                            id='chargePoints'
                            options={chargePoints && chargePoints.map((item) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                }
                            })}
                            defaultValue={() => {
                                if (chargePoint) {
                                    return {
                                        label: chargePoint.name,
                                        value: chargePoint.id
                                    }
                                }
                                return null;
                            }}
                            isMulti={false}
                            onChange={(e) => getChargePoint(e)}
                            placeholder="Выберите станцию"
                            maxMenuHeight={150}
                            className='w-75'
                        />
                    </div>
                    <button className='btn btn-success btn-zap mt-3'
                            onClick={() => dispatch(chargeActions.initCharging())}
                            disabled={!chargePoint}><BiSolidZap/>
                    </button>
                </div>
            </div>
            {
                step === 0 ?
                    <SwipeModal hide={cancelCharging} body={() => getSwiper(dataChooseConnector)}
                                name={"Коннектор"} success={onSuccessChooseConnector}
                                showBtn={true}
                                blockBtn={() => {
                                    if (chargePoint) {
                                        let choose = chargePoint.connectorStatuses.find(p => p.id === swiperValue.id);
                                        if (choose) {
                                            return choose.status !== 'Preparing'
                                        }
                                    }
                                    return true;
                                }}
                                nameBtn="Выбрать коннектор"/> :
                    step === 1 ?
                        <SwipeModal hide={cancelCharging}
                                    body={() => bodies.chooseCountKWh(Math.round(priceOneKWh * countKWh * 100) / 100, handleSetCountKWh)}
                                    name={"Количество кВт"} success={onSuccessChooseCountKWh}
                                    showBtn={true}
                                    blockBtn={() => false}
                                    nameBtn="Далее"/> :
                        step === 2 ?
                            <SwipeModal hide={cancelCharging} body={() => getSwiper(dataChoosePayment)}
                                        name={"Способ оплаты"} success={onSuccessChoosePayment}
                                        showBtn={true}
                                        blockBtn={() => false}
                                        nameBtn="Оплатить"/> :
                            step === 3 ?
                                <SwipeModal hide={cancelCharging} body={bodies.waitForPay}
                                            name={"Ожидание оплаты"} showBtn={false}
                                            blockBtn={() => false}/> :
                                step === 4 ?
                                    <SwipeModal hide={cancelCharging} body={bodies.waitForCharging}
                                                name={"Идет зарядка"} success={onSuccessWaitCharging} showBtn={true}
                                                blockBtn={() => false} nameBtn="Закончить"/> :
                                    step === 5 ?
                                        <SwipeModal hide={cancelCharging} body={bodies.failedPayment}
                                                    name={"Недостаточно средств"}
                                                    success={() => {
                                                        cancelCharging();
                                                        history.navigate('/add-balance');
                                                    }}
                                                    showBtn={true} blockBtn={() => false} nameBtn="Пополнить"/> :
                                        null
            }
        </div>
    )
        ;
}

export default Home;