import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {backActions} from "../../store";

function ContactUs() {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(backActions.needBack("/profile"));
        return(()=>{
            dispatch(backActions.dispose());
        });
    }, [dispatch]);

    return (
        <div className='row text-center'>
            <div>
                Почта: <a href="mailto:kazzholpv@mail.ru">kazzholpv@mail.ru</a>
            </div>
            <div>
                Телефон: <a href="tel:+77779373032">+7 777 937 30 32</a>
            </div>
        </div>
    );
}

export default ContactUs;