import React, { Component } from 'react';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons';

import './styles/sidebar.css';
import SubMenu from './SubMenu';

export class Sidebar extends Component {
  static displayName = Sidebar.name;

  render() {
    return (
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className={this.props.visible ? 'sidebar-menu active' : 'sidebar-menu'}>
          <ul className='sidebar-menu-items'>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} hide={this.props.hide}/>;
              })}
          </ul>
        </nav>
      </IconContext.Provider>
    );
  }
}
