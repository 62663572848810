import React from "react";
import {motion} from "framer-motion";
import {BiSolidZap} from "react-icons/bi";
import {FaMoneyCheck} from "react-icons/fa";

export const bodies = {
    chooseConnector: (chargePoint) => {
        return chargePoint ?
            chargePoint.connectorStatuses.map((item, index) => {
                return {
                    id: item.id,
                    name: item.name,
                    active: item.status === 'Preparing'
                }
            }) : []
    },
    chooseCountKWh: (price, setCountKWh) => {
        const containerStyle = {
            position: "relative",
            width: "100%",
            height: "80px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        };
        const contentStyle = {
            position: "relative",
            width: "200px",
            height: "80px",
            boxSizing: "border-box",
        };
        const priceStyle = {
            textAlign: "center",
        }
        const countStyle = {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: "10px",
            marginBottom: "10px",
        }
        const countInputStyle = {
            width: "80px",
            textAlign: "center",
        }
        return <div style={containerStyle}>
            <div style={contentStyle}>
                <div style={countStyle}>
                    <input style={countInputStyle} className="form-control" type="number" onChange={setCountKWh}
                           defaultValue={0}/>
                </div>
                <div style={priceStyle}>Сумма: {price} ₸</div>
            </div>
        </div>;
    },
    choosePayment: () => {
        return [
            {
                id: 1,
                name: 'Баланс',
                active: true
            }
        ];
    },
    waitForPay: () => {
        const containerStyle = {
            position: "relative",
            width: "100px",
            height: "100px",
            boxSizing: "border-box"
        };

        const circleStyle = {
            display: "block",
            width: "100px",
            height: "100px",
            border: "0.5rem solid #e9e9e9",
            borderTop: "0.5rem solid #3498db",
            borderRadius: "50%",
            position: "absolute",
            boxSizing: "border-box",
            top: 0,
            left: 0
        };

        const spinTransition = {
            repeat: Infinity,
            ease: "linear",
            duration: 1.5
        };
        return (
            <div className='d-flex w-100 justify-content-center align-content-center'>
                <div style={containerStyle}>
                    <motion.span
                        style={circleStyle}
                        animate={{rotate: 360}}
                        transition={spinTransition}
                    />
                </div>
            </div>
        );
    },
    waitForCharging: () => {
        const containerStyle = {
            position: "relative",
            width: "100px",
            height: "100px",
            boxSizing: "border-box"
        };

        const target = {
            x: "0%",
            opacity: 0,
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: "linear",
            },
            transitionEnd: {display: "none"}
        }

        return (
            <div className='d-flex w-100 justify-content-center align-content-center'>
                <div style={containerStyle} className='d-flex justify-content-center align-items-center'>
                    <motion.div
                        animate={target}
                    >
                        <BiSolidZap size={60} color='#efef4a'/>
                    </motion.div>
                </div>
            </div>
        );
    },
    failedPayment: () => {
        return (
            <div className='d-flex w-100 justify-content-center align-content-center'>
                <div className='d-flex justify-content-center align-items-center'>
                    <FaMoneyCheck size={60}/>
                </div>
            </div>
        );
    }
}