import React, {useCallback, useEffect} from 'react';
import Select from "react-select";
import * as TbIcons from "react-icons/tb";
import {fetchWrapper} from "../../../../helpers/fetchwrapper";

function ModalInfo({id}) {
    const [chosen, setChosen] = React.useState(null);
    const [chosenConnector, setChosenConnector] = React.useState(null);
    const [chosenRate, setChosenRate] = React.useState(null);

    const updateInfo = useCallback(() => {
        fetchWrapper.get(`api/chargepoints/${id}`).then(data => {
            setChosen(data);
        });
    }, [id]);

    useEffect(() => {
        updateInfo();
    }, [updateInfo])

    return (
        <>
            {
                chosen != null ?
                    <>
                        <input type="hidden" id="id" value={id}/>
                        <div className="row">
                            <div className="form-group col-7">
                                <label htmlFor="name">Название</label>
                                <div className="rounded border border-secondary pt-2 pb-2 ps-2 pe-2">
                                    <span>{chosen.name}</span>
                                </div>
                            </div>
                            <div className="form-group col-5">
                                <label htmlFor="name">Статус</label>
                                <div
                                    className="rounded border border-secondary pt-1
                                    pb-1 ps-2 pe-2 d-flex justify-content-between align-items-center">
                                    <span>{chosen.status}</span>
                                    <button
                                        className="btn btn-sm btn-info ms-2 text-white"
                                        onClick={updateInfo}
                                    >
                                        <TbIcons.TbRefresh className="fs-4"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12">
                                <label htmlFor="address">Адрес</label>
                                <div className="rounded border border-secondary pt-2 pb-2 ps-2 pe-2">
                                    <span>{chosen.address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 border border-dark p-2 rounded">
                            <h6>Коннекторы</h6>
                            {
                                chosen.connectorStatuses.map((item) => {
                                    return (
                                        <div key={item.id} className="row">
                                            <div className="form-group col-3">
                                                <label>Номер</label>
                                                <input type="text" className="form-control" name="id-connector"
                                                       placeholder="Введите номер"
                                                       defaultValue={item.id} readOnly={true}/>
                                            </div>
                                            <div className="form-group col-4">
                                                <label>Название</label>
                                                <input type="text" className="form-control" name="name-connector"
                                                       placeholder="Введите название"
                                                       defaultValue={item.name} readOnly={true}/>
                                            </div>
                                            <div className="form-group col-5">
                                                <label>Статус</label>
                                                <input type="text" className="form-control" name="status-connector"
                                                       placeholder="Введите статус"
                                                       defaultValue={item.status} readOnly={true}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="mt-3 border border-dark p-2 rounded">
                            <h6>Управление</h6>
                            {
                                chosen.status === "Online" ?
                                    <>
                                        <Select
                                            id='rates'
                                            options={
                                                chosen.connectorStatuses.filter(p => p.status === "Preparing" || p.status === "Charging" || p.status === "Occupied").map((item) => {
                                                    return {
                                                        value: item.id,
                                                        label: item.name
                                                    }
                                                })
                                            }
                                            isMulti={false}
                                            value={chosenConnector}
                                            onChange={(e) => {
                                                setChosenConnector(e)
                                            }}
                                            placeholder="Выберите коннектор"
                                        />
                                        <Select
                                            id='rates'
                                            className="mt-2"
                                            options={
                                                chosen.chargeRates.map((item) => {
                                                    return {
                                                        value: item.id,
                                                        label: item.name
                                                    }
                                                })
                                            }
                                            isMulti={false}
                                            value={chosenRate}
                                            onChange={(e) => {
                                                setChosenRate(e)
                                            }}
                                            placeholder="Выберите тариф"
                                        />
                                        <div className="d-flex justify-content-between mt-2">
                                            <button
                                                className="btn btn-sm btn-success col-5"
                                                disabled={
                                                    !chosenConnector || !chosenRate ||
                                                    chosen.connectorStatuses.find(p => p.id === chosenConnector.value && p.status === "Preparing") === undefined
                                                }
                                                onClick={() => {
                                                    fetchWrapper.post('api/chargepoints/remotestart', {
                                                        id: chosen.id,
                                                        connectorId: chosenConnector.value,
                                                        rateId: chosenRate.value
                                                    });
                                                }}
                                            >
                                                Запустить
                                            </button>
                                            <button
                                                className="btn btn-sm btn-danger col-5"
                                                disabled={
                                                    !chosenConnector ||
                                                    chosen.connectorStatuses.find(p => p.id === chosenConnector.value && (p.status === "Charging" || p.status === "Occupied")) === undefined
                                                }
                                                onClick={() => {
                                                    fetchWrapper.post('api/chargepoints/remotestop', {
                                                        id: chosen.id,
                                                        connectorId: chosenConnector.value
                                                    });
                                                }}
                                            >
                                                Остановить
                                            </button>
                                        </div>
                                    </> :
                                    <div className="text-danger text-center">
                                        Станция оффлайн
                                        <br/>
                                        Управление не доступно
                                    </div>
                            }
                        </div>
                    </>
                    : null
            }
        </>
    );
};

export default ModalInfo;