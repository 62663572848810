import React, {useEffect, useState} from "react";
import moment from "moment";

import {fetchWrapper} from "../../helpers/fetchwrapper";

function Stats() {
    const [load, setLoad] = useState(false);
    const [stats, setStats] = useState([]);

    useEffect(() => {
        setLoad(true);
        fetchWrapper.get('api/orders/history').then(data => {
            setStats(data)
        }).finally(() => {
            setLoad(false);
        })
    }, [])

    return (
        <div className="row g-3 ps-2 pe-2">
            {
                load ?
                    <div className='text-center'>Загружаем данные</div> :
                    stats.map((stat, index) => {
                        return <div key={index} className="card p-3">
                            <div className="d-flex justify-content-between mb-2">
                                <div>Номер: {stat.orderId}</div>
                                <div>{moment(stat.dateCreate).format('DD.MM.YYYY HH:mm:ss')}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Тип: {stat.type === 1 ? 'Оплата' : 'Возврат'}</div>
                                <div>Сумма: {stat.sum} ₸</div>
                            </div>
                        </div>
                    })
            }
        </div>
    );
}

export default Stats;