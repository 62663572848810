import React from 'react';
import * as TbIcons from 'react-icons/tb';

import {Role} from '../../helpers/role';

export const SidebarData = [
    {
        title: 'Зарядные станции',
        path: '/admin/chargepoints',
        icon: <TbIcons.TbBuildingHospital/>,
        cName: 'sidebar-text',
        roles: [Role.Admin]
    },
    {
        title: 'Пользователи',
        path: '/admin/users',
        icon: <TbIcons.TbUsers/>,
        cName: 'sidebar-text',
        roles: [Role.Admin]
    },
    {
        title: 'Карты',
        path: '/admin/chargetags',
        icon: <TbIcons.TbDeviceTablet/>,
        cName: 'sidebar-text',
        roles: [Role.Admin]
    },
    {
        title: 'Тарифы',
        path: '/admin/chargerates',
        icon: <TbIcons.TbFilePower/>,
        cName: 'sidebar-text',
        roles: [Role.Admin]
    },
    {
        title: 'Настройки',
        path: '/admin/settings',
        icon: <TbIcons.TbSettings/>,
        cName: 'sidebar-text',
        roles: [Role.Admin]
    },
    {
        title: 'Вернуться к зарядке',
        path: '/',
        icon: <TbIcons.TbArrowBack/>,
        cName: 'sidebar-text',
        roles: [Role.All]
    }
];