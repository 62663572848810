import React from 'react';
import * as FaIcons from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {IconContext} from 'react-icons';

import PrivateComponent from "./PrivateComponent";

import {useSelector, useDispatch} from 'react-redux';

import {Role} from '../../helpers/role';
import {history} from '../../helpers/history';
import {authActions} from '../../store';

import './styles/navbar.css';

export default Navbar;

function Navbar(props) {
    const dispatch = useDispatch();
    const {user: authUser} = useSelector(x => x.auth);

    function logout() {
        return dispatch(authActions.logout())
            .unwrap()
            .then(() => {
                history.navigate('/login');
            });
    }

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <div className={Role.hasRole([Role.Admin], authUser.roles) ? 'navbar' : 'navbar navbar-right-position'}>
                    <PrivateComponent>
                        <Link to='#' className='navbar-burger d-flex align-items-center' roles={[Role.Admin]}>
                            <FaIcons.FaBars onClick={props.setShowSidebar}/>
                        </Link>
                    </PrivateComponent>
                    <li className='nav-item dropdown'>
                        <Link to='#' className='nav-link dropdown-toggle' id='nav-user' role='button'
                              data-bs-toggle='dropdown' aria-expanded='false'>
                            Привет, {authUser.firstName}!
                        </Link>
                        <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                            <li><Link className='dropdown-item' to='#' onClick={logout}>Выход</Link></li>
                        </ul>
                    </li>
                </div>
            </IconContext.Provider>
        </>
    );
}
