import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import PrivateRoute from "./components/shared/PrivateRoute";

import Layout from "./components/shared/Layout";
import Home from "./components/home/Home";
import Chargings from "./components/chargings/Chargings";
import Stats from "./components/stats/Stats";
import Profile from "./components/profile/Profile";
import AddBalance from "./components/profile/AddBalance";
import ContactUs from "./components/profile/ContactUs";
import Docs from "./components/profile/Docs";

import LayoutAdmin from "./components/shared/LayoutAdmin";
import ChargePoints from "./components/administration/chargePoints/ChargePoints";
import Users from "./components/administration/users/Users";
import ChargeTags from "./components/administration/chargeTags/ChargeTags";
import ChargeRates from "./components/administration/chargeRates/ChargeRates";
import Settings from "./components/administration/settings/Settings";

import Login from "./components/login/Login";
import Register from "./components/login/Register";
import ForgetPassword from "./components/login/ForgetPassword";

import {history} from './helpers/history';
import {Role} from "./helpers/role";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {useEffect} from "react";
import {useSelector} from "react-redux";

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

function App() {
    const back = useSelector((store) => store.back.need);

    history.navigate = useNavigate();
    history.location = useLocation();

    useEffect(()=>{
        if(back){
            document.documentElement.style.setProperty('--needHeight', `155px`);
        }
        else{
            document.documentElement.style.setProperty('--needHeight', `95px`);
        }
    }, [back]);

    return (
        <Routes>
            <Route exact element={<PrivateRoute/>}>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="/chargings" element={<Chargings/>}/>
                    <Route path="/stats" element={<Stats/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/add-balance" element={<AddBalance/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                    <Route path="/docs" element={<Docs/>}/>
                </Route>
                <Route exact element={<PrivateRoute roles={[Role.Admin]}/>}>
                    <Route path="/admin" element={<LayoutAdmin/>}>
                        <Route path="/admin/chargepoints" element={<ChargePoints/>}/>
                        <Route path="/admin/users" element={<Users/>}/>
                        <Route path="/admin/chargetags" element={<ChargeTags/>}/>
                        <Route path="/admin/chargerates" element={<ChargeRates/>}/>
                        <Route path="/admin/settings" element={<Settings/>}/>
                    </Route>
                </Route>
            </Route>
            <Route path='/login' element={<Login/>}/>
            <Route path='/forget-password' element={<ForgetPassword/>}/>
            <Route path='/register' element={<Register/>}/>
        </Routes>
    );
}

export default App;
