import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { history } from '../../helpers/history';

import './styles/login.css';
import { Link } from 'react-router-dom';
import {fetchWrapper} from "../../helpers/fetchwrapper";

export default ForgetPassword;

function ForgetPassword() {
    const authUser = useSelector(x => x.auth.user);
    const [restored, setRestored] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (authUser) {
            history.navigate('/');
        }
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Некорректный email').required('Email обязателен'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email }) {
        setError(null);
        return fetchWrapper.post('api/users/forget-password', { email })
            .then(() => setRestored(true))
            .catch((response) => setError(response));
    }

    return (
        <div className='d-flex justify-content-center h-100 align-items-center p-0'>
            <div className="card">
                <h4 className="card-header">Восстановление пароля</h4>
                <div className="card-body">
                    {!restored ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Email</label>
                                <input name="email" type="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>
                            <div className='w-100 d-flex justify-content-between'>
                                <Link to="/login" disabled={isSubmitting} className="btn btn-secondary mt-2">Назад</Link>
                                <button disabled={isSubmitting} className="btn btn-primary mt-2">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Далее
                                </button>
                            </div>
                            {error ? <div className="alert alert-danger mt-3 mb-0">{error}</div> : ''}
                        </form> :
                        <>
                            <div className="alert alert-success mt-3 mb-0">
                                Временный пароль отправлен вам на почту
                            </div>
                            <div className='w-100 d-flex justify-content-center'>
                                <Link to="/login" className="btn btn-primary mt-2">Вернуться</Link>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    )
}
