import React, {useState} from 'react';
import Navbar from "./Navbar";
import {Sidebar} from "./Sidebar";
import {Outlet} from "react-router-dom";
import PrivateComponent from "./PrivateComponent";

import {Role} from '../../helpers/role';

import './styles/content-admin.css';

export default LayoutAdmin;

function LayoutAdmin(props) {
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <>
            <PrivateComponent>
                <Navbar setShowSidebar={() => setShowSidebar(!showSidebar)} roles={[Role.Admin]}/>
                <Sidebar visible={showSidebar} roles={[Role.Admin]}/>
            </PrivateComponent>
            <div className='content admin'>
                <Outlet/>
            </div>
        </>
    );
}
