import React from 'react';
import Menu from "./Menu";
import Back from "./Back";
import {Outlet} from "react-router-dom";

import './styles/content.css';
import {useSelector} from "react-redux";

export default Layout;

function Layout(props) {
    const back = useSelector((store) => store.back.need);

    return (
        <>
            {
                back ?
                    <Back/>
                    : null
            }
            <div className='content'>
                <Outlet/>
            </div>
            <Menu/>
        </>
    );
}
