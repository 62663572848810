import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';

import {history} from '../../helpers/history';

import './styles/login.css';
import {Link} from 'react-router-dom';
import {fetchWrapper} from "../../helpers/fetchwrapper";

import Dogovor from "@content/docs/pdf/dogovor.pdf";
import Policy from "@content/docs/pdf/policy.pdf";

export default Register;

function Register() {
    const authUser = useSelector(x => x.auth.user);
    const [registration, setRegistration] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (authUser) {
            history.navigate('/');
        }
    });

    const validationSchema = Yup.object().shape({
        lastName: Yup.string().required('Фамилия обязательна'),
        middleName: Yup.string().notRequired(),
        firstName: Yup.string().required('Имя обязательно'),
        email: Yup.string().email('Некорректный email').required('Email обязателен'),
        agree: Yup.bool().oneOf([true], 'Необходимо ваше согласие')
    });
    const formOptions = {resolver: yupResolver(validationSchema)};

    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    function onSubmit({lastName, firstName, middleName, email}) {
        setError(null);
        return fetchWrapper.post('api/users/register', {lastName, firstName, middleName, email})
            .then(() => setRegistration(true))
            .catch((response) => setError(response));
    }

    return (
        <div className='d-flex justify-content-center h-100 align-items-center p-0'>
            <div className="card">
                <h4 className="card-header">Регистрация</h4>
                <div className="card-body">
                    {!registration ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Фамилия</label>
                                <input name="lastName" type="text" {...register('lastName')}
                                       className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.lastName?.message}</div>
                            </div>
                            <div className="form-group">
                                <label>Имя</label>
                                <input name="firstName" type="text" {...register('firstName')}
                                       className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.firstName?.message}</div>
                            </div>
                            <div className="form-group">
                                <label>Отчество</label>
                                <input name="middleName" type="text" {...register('middleName')}
                                       className={`form-control ${errors.middleName ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.middleName?.message}</div>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input name="email" type="email" {...register('email')}
                                       className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>
                            <div className="form-check mt-2 align-items-center">
                                <input name="agree" type="checkbox" {...register('agree')}
                                       className={`form-check-input me-2 ${errors.agree ? 'is-invalid' : ''}`}/>
                                <label className='form-check-label' style={{fontSize: 10}}>Cогласен с
                                    <a href={Dogovor}
                                       style={{margin: "0 5px"}}
                                       rel="noopener noreferrer" target="_blank">договором-офертой</a> и
                                    <a href={Policy}
                                       style={{margin: "0 5px"}}
                                       rel="noopener noreferrer" target="_blank">политикой конфиденциальности</a>
                                </label>
                                <div className="invalid-feedback">{errors.agree?.message}</div>
                            </div>
                            <div className='w-100 d-flex justify-content-between'>
                                <Link to="/login" disabled={isSubmitting}
                                      className="btn btn-secondary mt-2">Назад</Link>
                                <button disabled={isSubmitting} className="btn btn-primary mt-2">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Далее
                                </button>
                            </div>
                            {error ? <div className="alert alert-danger mt-3 mb-0">{error}</div> : ''}
                        </form> :
                        <>
                            <div className="alert alert-success mt-3 mb-0">
                                Временный пароль отправлен вам на почту
                            </div>
                            <div className='w-100 d-flex justify-content-center'>
                                <Link to="/login" className="btn btn-primary mt-2">Вход</Link>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
