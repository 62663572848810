import React from "react";
import {IconContext} from 'react-icons';
import * as TbIcons from "react-icons/tb";

import {Link} from "react-router-dom";
import {history} from "../../helpers/history";
import {useSelector} from "react-redux";

import cardsLogos from "@content/images/png/cards-logos.png";

import './styles/menu.css';

export default Menu;

function Menu(props) {
    const transactions = useSelector((store) => store.charge.transactions);

    return (
        <>
            <div className="d-flex justify-content-center">
                <img src={cardsLogos} alt="cards-logos" className="cards-logos"/>
            </div>
            <IconContext.Provider value={{color: '#fff'}}>
                <div className='menu'>
                    <Link to='/' className={history.location.pathname === '/' ? 'menu-item active' : 'menu-item'}>
                        {<TbIcons.TbGasStation/>}
                        <div>Станции</div>
                    </Link>
                    <Link to='/chargings'
                          className={history.location.pathname === '/chargings' ? 'menu-item active' : 'menu-item'}>
                        {
                            transactions ?
                            <div className='menu-item-count'>{transactions.length}</div>
                            : null
                        }
                        {<TbIcons.TbBatteryCharging/>}
                        <div>Зарядки</div>
                    </Link>
                    <Link to='/stats'
                          className={history.location.pathname === '/stats' ? 'menu-item active' : 'menu-item'}>
                        {<TbIcons.TbCalendarStats/>}
                        <div>Статистика</div>
                    </Link>
                    <Link to='/profile'
                          className={history.location.pathname === '/profile' ? 'menu-item active' : 'menu-item'}>
                        {<TbIcons.TbUser/>}
                        <div>Профиль</div>
                    </Link>
                </div>
            </IconContext.Provider>
        </>
    );
}
