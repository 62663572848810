import {createSlice} from '@reduxjs/toolkit';

const name = 'back';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({name, initialState, reducers});

export const backActions = { ...slice.actions };
export const backReducer = slice.reducer;

function createInitialState() {
    return {
        need: false,
        path: null
    }
}

function createReducers() {
    return {
        needBack,
        dispose
    };

    function needBack(state, path) {
        state.need = true;
        state.path = path.payload;
    }

    function dispose(state) {
        state.need = false;
        state.path = null;
    }
}