import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {backActions} from "../../store";
import styles from "./styles/profile.module.css";

function AddBalance() {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const [addBalance, setAddBalance] = useState(1000);
    const [transactionId] = useState("id" + Math.random().toString(16).slice(2));
    const form = useRef();

    useEffect(() => {
        dispatch(backActions.needBack("/profile"));
        return (() => {
            dispatch(backActions.dispose());
        });
    }, [dispatch]);

    return (
        <div className='row text-center'>
            <div className="d-flex justify-content-center align-items-end">
                <div className="form-group">
                    <label htmlFor="addBalance" className="text-start">Сумма, тг</label>
                    <input type="number" className="form-control" id="addBalance"
                           placeholder="Сумма" value={addBalance}
                           onChange={(e) => setAddBalance(e.target.value)}/>
                </div>
                <div className="btn btn-primary ms-2" onClick={() => {
                    if (form && form.current) {
                        form.current.submit();
                    }
                }}>Пополнить
                </div>
                <form action="https://kaspi.kz/online" method="post" id="kaspikz-form" ref={form}>
                    <input type="hidden" name="TranId"
                           value={transactionId}/>
                    <input type="hidden" name="OrderId" value={user.id}/>
                    <input type="hidden" name="Amount" value={addBalance * 100}/>
                    <input type="hidden" name="Service" value="KBMChargers"/>
                    <input type="hidden" name="returnUrl" value="https://kbmc.kz/profile"/>
                </form>
            </div>
        </div>
    );
}

export default AddBalance;