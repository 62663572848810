import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper/modules";
import {motion} from "framer-motion";

export default CustomSwiper;

const containerStyle = {
    position: "relative",
    width: "100px",
    height: "100px",
    boxSizing: "border-box"
};

const circleStyle = {
    display: "block",
    width: "100px",
    height: "100px",
    border: "0.5rem solid #e9e9e9",
    borderTop: "0.5rem solid #3498db",
    borderRadius: "50%",
    position: "absolute",
    boxSizing: "border-box",
    top: 0,
    left: 0
};

const spinTransition = {
    repeat: Infinity,
    ease: "linear",
    duration: 1.5
};

function CustomSwiper({data, object}) {
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        if (swiper && swiper.slides) {
            let item = {
                id: data[swiper.activeIndex].id,
                tag: data[swiper.activeIndex].tag
            };
            object(item);
        }
    }, [data, object, swiper]);

    return (
        <>
            {
                data ?
                    <Swiper
                        style={{maxHeight: 200}}
                        freeMode={true}
                        direction={"vertical"}
                        slidesPerView={"auto"}
                        mousewheel={true}
                        centeredSlides={true}
                        modules={[Pagination]}
                        onInit={(e) => setSwiper(e)}
                        onSlideChange={(e) => {
                            let item = {
                                id: data[e.activeIndex].id,
                                tag: data[e.activeIndex].tag,
                            };
                            object(item);
                        }}
                    >
                        {
                            data.map((item, index) => {
                                return (
                                    <SwiperSlide key={index} data-value={item.id}>
                                        <div
                                            className={item.active ? 'swiper-slide-value' : 'swiper-slide-value unavaible'}>
                                            {item.name}
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper> :
                    <div className='d-flex w-100 justify-content-center align-content-center'>
                        <div style={containerStyle}>
                            <motion.span
                                style={circleStyle}
                                animate={{rotate: 360}}
                                transition={spinTransition}
                            />
                        </div>
                    </div>
            }
        </>
    );
}