import React from 'react';

import Widget from '../../shared/templates/Widget';
import ChargeRatesTable from './ChargeRatesTable';

import { fetchWrapper } from '../../../helpers/fetchwrapper';

export const Add = async () => {
    return ({
        title: 'Добавление тарифа',
        body:
            <>
                <div className="form-group">
                    <label htmlFor="name">Название</label>
                    <input type="text" className="form-control" id="name" placeholder="Введите название" />
                </div>
                <div className="form-group">
                    <label htmlFor="limit">Ограничение, кВт</label>
                    <input type="number" className="form-control" id="limit" placeholder="Введите ограничение" />
                </div>
                <div className="form-group">
                    <label htmlFor="price">Цена, тг</label>
                    <input type="number" className="form-control" id="price" placeholder="Введите цену" />
                </div>
            </>,
        saveName: 'Добавить',
        saveFunc: function (body) {
            let data = {
                name: body.find('#name').val(),
                limit: body.find('#limit').val(),
                price: body.find('#price').val()
            };

            return fetchWrapper.post('api/chargerates/add', data);
        }
    });
}

export const Edit = async (id) => {
    let chosen = await fetchWrapper.get(`api/chargerates/${id}`);
    return ({
        title: 'Изменение тарифа',
        body:
            <>
                <input type="hidden" id="id" value={id} />
                <div className="form-group">
                    <label htmlFor="name">Название</label>
                    <input type="text" className="form-control" id="name" placeholder="Введите название" defaultValue={chosen.name} />
                </div>
                <div className="form-group">
                    <label htmlFor="limit">Ограничение, кВт</label>
                    <input type="number" className="form-control" id="limit" placeholder="Введите ограничение" defaultValue={chosen.limit} />
                </div>
                <div className="form-group">
                    <label htmlFor="price">Цена, тг</label>
                    <input type="number" className="form-control" id="price" placeholder="Введите цену" defaultValue={chosen.price} />
                </div>
            </>
        ,
        saveName: 'Сохранить',
        saveFunc: function (body) {
            let data = {
                id: body.find('#id').val(),
                name: body.find('#name').val(),
                limit: body.find('#limit').val(),
                price: body.find('#price').val()
            };

            return fetchWrapper.post('api/chargerates/edit', data);
        }
    });
}

export default ChargeRates;

function ChargeRates(props) {

    return (
        <>
            <Widget>
                <h4>Тарифы</h4>
                <ChargeRatesTable />
            </Widget>
        </>
    );
}
