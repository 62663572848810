import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Widget from '../../shared/templates/Widget';

import {settingsActions} from "../../../store";

export default ChargeTags;

function ChargeTags(props) {
    const dispatch = useDispatch();
    const priceOneKWh = useSelector((store) => store.settings.priceOneKWh);
    const priceOneKWhRef = useRef();

    useEffect(() => {
        dispatch(settingsActions.getPriceOneKWh())
    }, [dispatch])

    const setSettings = () => {
        if (priceOneKWhRef.current) {
            dispatch(settingsActions.setDouble({name: 'PriceOneKWh', value: priceOneKWhRef.current.value}));
        }
    }

    return (
        <>
            <Widget>
                <h4>Настройки</h4>
                <div className="row">
                    <div className="col-md-3">
                        <div className="mb-3">
                            <label className="form-label">Цена за кВт, тг</label>
                            <input className="form-control" type="number" defaultValue={priceOneKWh}
                                   ref={priceOneKWhRef}/>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary" onClick={setSettings}>Сохранить</button>
            </Widget>
        </>
    );
}
